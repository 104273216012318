<template>
  <footer class="bg-lb-primary-grad dark:bg-primary  px-4 py-3 text-white/75">
    <div class="flex flex-row flex-wrap-reverse justify-between items-center">
      <NuxtLink class="text-decoration-none block" to="/">
        Local Bytes © Allport IT Ltd
      </NuxtLink>
      <PageSocials/>
    </div>
  </footer>
</template>
